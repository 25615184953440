"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.familyTree = exports.computeId = exports.computePath = exports.mergeProps = exports.isModuleVariables = exports.getModuleProps = exports.isIdenticalFlatArray = void 0;
const coreTypes_1 = require("./coreTypes");
const json_1 = require("./json");
/**
 * Compares the elements of two flat arrays efficiently and reliably.
 * @param a1 operand array 1
 * @param a2 operand array 2
 * @returns boolean indicating whether the two arrays are equal
 */
function isIdenticalFlatArray(a1, a2) {
    // Tim Down: https://jsben.ch/1Mq8s
    let i = a1.length;
    if (i !== a2.length)
        return false;
    while (i--) {
        if (a1[i] !== a2[i])
            return false;
    }
    return true;
}
exports.isIdenticalFlatArray = isIdenticalFlatArray;
const getModuleProps = (core, module) => {
    // Both of these values are set at the group parent level and then passed down
    // through `overrides` via `nodeToPageModule`
    if (core.coreType !== 'group' || !(0, exports.isModuleVariables)(module.variables)) {
        return { moduleProps: null, staffOverrides: null };
    }
    const { componentFieldData: moduleProps = null, ...staffOverrides } = module.variables._instance;
    return { moduleProps, staffOverrides };
};
exports.getModuleProps = getModuleProps;
const isModuleVariables = (v) => {
    return ((0, json_1.isJSONObject)(v) &&
        '_version' in v &&
        typeof v._version === 'number' &&
        '_instance' in v &&
        (0, json_1.isJSONObject)(v._instance));
};
exports.isModuleVariables = isModuleVariables;
/**
 * Merge props of different layers of config.
 * @param layers each later of for props for a module.
 * At the time of writing, this only includes core and then group instance
 * overrides. It was handled before by simply spreading, but styleAttr is a
 * special case. There will likely be other special cases.
 * @returns
 */
const mergeProps = (layers) => {
    return layers.reduce((acc, layer) => {
        if (layer === null) {
            return acc;
        }
        const extras = {};
        if ('styleAttr' in layer && 'styleAttr' in acc) {
            if (layer.styleAttr === 'null') {
                extras.styleAttr = null;
            }
            else {
                extras.styleAttr = `${acc.styleAttr}; ${layer.styleAttr}`;
            }
        }
        return { ...acc, ...layer, ...extras };
    }, {});
};
exports.mergeProps = mergeProps;
/**
 * Use `reduceRight` as an advanced `filter` of `lineageAll` to list only the module ids
 * required to describe a module's position. `lineageAll` includes every component layer
 * of the current page, but if used as a `path` it would severely complicate editing
 * groups since every child or step-child would need its `path` changed.)
 * @param lineageAll describes the entire module id path up to here.
 * @param all contains all the modules, cores, and components in the module graph from which path will be computed.
 * @param parentId the core this module is trying to connect to.
 * @param parentMid the module id of the module with `parentId` since we are evaluating a specific
 *                  module, not a core (as cores may be attached to many modules)
 * @returns the subset of `lineageAll` that describes the path for this module.
 */
function computePath(lineageAll, all, parentId, parentMid, groupContext) {
    const { modules, cores } = all;
    let nextGroupId;
    if (!parentId) {
        return [];
    }
    // Since the path is being derived for a new/candidate child, include this level.
    const lineage = typeof parentMid === 'string' ? [...lineageAll, parentMid] : lineageAll;
    const returnedPath = lineage.reduceRight(({ isDone, path }, mid, i, arr) => {
        if (isDone) {
            return { isDone, path };
        }
        const module = modules[mid];
        const core = cores[module.coreId];
        const isInGroup = !!module.groupId;
        if (
        // This parent IS the group context, so no path needed.
        core.id === groupContext ||
            // This parent is in the same context as the group context and is not a
            // group itself, so no path needed. (if the parent is a group, we will need
            // to identify its moduleId in the path, so this is not a valid case for this conditional)
            (isInGroup &&
                module.groupId === groupContext &&
                core.coreType === coreTypes_1.coreTypes.default)) {
            return { isDone: true, path };
        }
        if (
        // Positionally situated directly in slot left open by a group?
        // ie, first-encountered element in reduceRight is part of a group.
        (arr.length === i + 1 &&
            (core.coreType === coreTypes_1.coreTypes.group || isInGroup)) ||
            // This is the GID you are looking for.
            nextGroupId === core.id) {
            path.unshift(mid);
            nextGroupId = module.groupId;
        }
        // This level is attached to the page, so don't go on to further-left MIDs.
        if (module.pageId) {
            return { isDone: true, path };
        }
        else {
            return { isDone: false, path };
        }
    }, { isDone: false, path: [] });
    return returnedPath.path;
}
exports.computePath = computePath;
const getSixOctets = (uuid) => uuid.replace('-', '').slice(0, 12);
/**
 * Computes an HTML safe identifier from the given `ids`. The computed identifier is not used
 * within the system but exists as a "hook" for custom styles and behavior.
 * @param ids from which HTML id will be computed
 * @returns a sufficiently unique subset of the values in `RelevantIdentifiers` prefixed such that
 * it will always be a valid HTML id.
 */
const computeId = (ids) => {
    if (ids.groupModuleId && ids.groupModuleId !== ids.mid) {
        return `i${getSixOctets(ids.groupModuleId)}_${getSixOctets(ids.mid)}`;
    }
    return `i${getSixOctets(ids.mid)}`;
};
exports.computeId = computeId;
/**
 * This is to build up a simple tree that shows just module relationships. It allows
 * us to then build up the guest view or the admin view. It's also used to build a
 * selectable XML model for `about`.
 * @param modules the array of modules at this level to convert into nodes
 * @param all AllPageParts
 * @param lineage array of moduleIds up the tree
 * @returns FamilyTreeNode[], each can have kids
 */
function familyTree(modules, all, parentId = null, lineage = []) {
    return modules.map((module) => {
        const core = all.cores[module.coreId];
        const component = all.components[core.componentId];
        const node = {
            kind: component.reactName,
            id: module.id,
            cid: core.id,
            path: module.path || undefined,
            parentId,
            lineage: [],
            lineageAll: [...lineage],
            coreType: core.coreType,
            i: (module.parentSlotIndex || module.pageIndex || 10) / 10 - 1,
        };
        node.lineage = (node.path && lineage) ?? [];
        const children = Object.values(all.modules).filter((candidate) => {
            // Only consider parentId===coreId.
            if (candidate.parentId !== module.coreId)
                return false;
            // Without a path, but parentId===coreId, the parent is a page-level module, so it's a child.
            if (candidate.path.length === 0)
                return true;
            return isIdenticalFlatArray(candidate.path, computePath(lineage, all, core.id, module.id, candidate.groupId));
        });
        if (children.length) {
            // need to sort here since children are assigned in random order.
            children.sort((a, b) => (a.parentSlotIndex ?? 0) < (b.parentSlotIndex ?? 0) ? -1 : 1);
            node.kids = familyTree(children, all, node.cid, [
                ...lineage,
                node.id,
            ]);
        }
        return node;
    });
}
exports.familyTree = familyTree;
